var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', [_c('b-card-title', [_c('b-row', [_c('b-col', [_c('div', [_vm._v("Role Management")])]), _c('b-col', [_vm.allowCreate() ? _c('div', {
    attrs: {
      "align": "right"
    }
  }, [_c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-sm",
      modifiers: {
        "modal-sm": true
      }
    }],
    staticClass: "btn-icon",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.ModalShow();
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "PlusIcon"
    }
  }), _vm._v("Tambah")], 1)], 1) : _vm._e(), _c('b-modal', {
    attrs: {
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "id": "modal-tambah",
      "centered": "",
      "size": "sm",
      "title": _vm.titleModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100"
        }, [_c('p', {
          staticClass: "float-left mb-0"
        }), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "mr-1 btn-icon",
          attrs: {
            "type": "submit",
            "variant": "outline-success"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.Tambah($event);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "SaveIcon"
          }
        }), _vm._v(_vm._s(_vm.label || "Tambah") + " ")], 1), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "float-right btn-icon",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.Modal = false;
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "LogOutIcon"
          }
        }), _vm._v("Tutup ")], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.Modal,
      callback: function callback($$v) {
        _vm.Modal = $$v;
      },
      expression: "Modal"
    }
  }, [_c('b-card-text', [_c('b-form', [_c('b-form-group', {
    attrs: {
      "label": "Level",
      "label-for": "level"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.levels,
      "label": "Level"
    },
    model: {
      value: _vm.form.level_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "level_id", $$v);
      },
      expression: "form.level_id"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Menu",
      "label-for": "menu"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.menus,
      "label": "Menu"
    },
    model: {
      value: _vm.form.menu_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "menu_id", $$v);
      },
      expression: "form.menu_id"
    }
  })], 1), _c('label', {
    staticClass: "mb-1"
  }, [_vm._v("Jenis Akses")]), _c('section', {
    staticClass: "d-flex align-items-center mb-1"
  }, [_c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.form.create,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "create", $$v);
      },
      expression: "form.create"
    }
  }, [_vm._v(" Tambah ")])], 1), _c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.form.update,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "update", $$v);
      },
      expression: "form.update"
    }
  }, [_vm._v(" Ubah ")])], 1), _c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.form.delete,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "delete", $$v);
      },
      expression: "form.delete"
    }
  }, [_vm._v(" Hapus ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-card-text', {
    staticClass: "blog-content-truncate"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "3",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "4",
      "sm": "8"
    }
  }, [_c('b-form-group', [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Level")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.levelOptions
    },
    model: {
      value: _vm.filteredLevel,
      callback: function callback($$v) {
        _vm.filteredLevel = $$v;
      },
      expression: "filteredLevel"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "5"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search",
      "placeholder": "Search......"
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function click($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn,
      "fields": _vm.fields,
      "items": _vm.data
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(index)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(create)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.create == 1 ? _c('feather-icon', {
          staticClass: "text-success",
          attrs: {
            "icon": "CheckIcon",
            "size": "24"
          }
        }) : _c('feather-icon', {
          staticClass: "text-danger",
          attrs: {
            "icon": "XIcon",
            "size": "24"
          }
        })];
      }
    }, {
      key: "cell(update)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.update == 1 ? _c('feather-icon', {
          staticClass: "text-success",
          attrs: {
            "icon": "CheckIcon",
            "size": "24"
          }
        }) : _c('feather-icon', {
          staticClass: "text-danger",
          attrs: {
            "icon": "XIcon",
            "size": "24"
          }
        })];
      }
    }, {
      key: "cell(delete)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.delete == 1 ? _c('feather-icon', {
          staticClass: "text-success",
          attrs: {
            "icon": "CheckIcon",
            "size": "24"
          }
        }) : _c('feather-icon', {
          staticClass: "text-danger",
          attrs: {
            "icon": "XIcon",
            "size": "24"
          }
        })];
      }
    }, {
      key: "cell(action)",
      fn: function fn(row) {
        return [_vm.allowUpdate() ? _c('b-button', {
          staticClass: "btn-icon",
          attrs: {
            "variant": "outline-info"
          },
          on: {
            "click": function click($event) {
              return _vm.ModalUbah(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1) : _vm._e(), _vm.allowDelete() ? _c('b-button', {
          staticClass: "btn-icon ml-1",
          attrs: {
            "variant": "outline-danger"
          },
          on: {
            "click": function click($event) {
              return _vm.ModalHapus(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1) : _vm._e()];
      }
    }, {
      key: "cell(Popularity)",
      fn: function fn(data) {
        return [_c('b-progress', {
          attrs: {
            "value": data.value.value,
            "max": "100",
            "variant": data.value.variant,
            "striped": ""
          }
        })];
      }
    }, {
      key: "cell(order_status)",
      fn: function fn(data) {
        return [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": data.value.variant
          }
        }, [_vm._v(" " + _vm._s(data.value.status) + " ")])];
      }
    }, {
      key: "cell(price)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s("$" + data.value) + " ")];
      }
    }, {
      key: "cell()",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.value) + " ")];
      }
    }])
  }), _c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }