<template>
  <b-overlay :show="loading">
    <b-card no-body>
      <b-card-body>
        <b-card-title>
          <b-row>
            <b-col><div>Role Management</div></b-col>
            <b-col>
              <div align="right" v-if="allowCreate()">
                <b-button
                  variant="outline-primary"
                  v-b-modal.modal-sm
                  @click="ModalShow()"
                  class="btn-icon"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />Tambah</b-button
                >
              </div>
              <b-modal
                no-close-on-backdrop
                no-close-on-esc
                v-model="Modal"
                id="modal-tambah"
                centered
                size="sm"
                :title="titleModal"
              >
                <b-card-text>
                  <b-form>
                    <b-form-group label="Level" label-for="level">
                      <b-form-select
                        v-model="form.level_id"
                        :options="levels"
                        label="Level"
                      />
                    </b-form-group>
                    <b-form-group label="Menu" label-for="menu">
                      <b-form-select
                        v-model="form.menu_id"
                        :options="menus"
                        label="Menu"
                      />
                    </b-form-group>
                    <label class="mb-1">Jenis Akses</label>
                    <section class="d-flex align-items-center mb-1">
                      <b-form-group>
                        <b-form-checkbox v-model="form.create" switch inline>
                          Tambah
                        </b-form-checkbox>
                      </b-form-group>
                      <b-form-group>
                        <b-form-checkbox v-model="form.update" switch inline>
                          Ubah
                        </b-form-checkbox>
                      </b-form-group>
                      <b-form-group>
                        <b-form-checkbox v-model="form.delete" switch inline>
                          Hapus
                        </b-form-checkbox>
                      </b-form-group>
                    </section>
                  </b-form>
                </b-card-text>

                <template #modal-footer>
                  <div class="w-100">
                    <p class="float-left mb-0"></p>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="outline-success"
                      class="mr-1 btn-icon"
                      @click.prevent="Tambah"
                    >
                      <feather-icon icon="SaveIcon" class="mr-25" />{{
                        label || "Tambah"
                      }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      class="float-right btn-icon"
                      @click="Modal = false"
                    >
                      <feather-icon icon="LogOutIcon" class="mr-25" />Tutup
                    </b-button>
                  </div>
                </template>
              </b-modal>
            </b-col></b-row
          >
        </b-card-title>
        <b-card-text class="blog-content-truncate">
          <b-row>
            <b-col md="3" sm="4" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"
                  >Per page</label
                >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="8" class="my-1">
              <b-form-group>
                <label class="d-inline-block text-sm-left mr-50">Level</label>
                <b-form-select
                  id="perPageSelect"
                  v-model="filteredLevel"
                  size="sm"
                  :options="levelOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col md="5" class="my-1">
              <b-form-group label-for="filterInput" class="mb-0">
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Search......"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- <b-col md="2" class="my-1">
            <b-button variant="primary" size="sm"
              ><feather-icon icon="TrashIcon" />Multiple Delete</b-button
            >
          </b-col> -->

            <b-col cols="12">
              <b-table
                striped
                small
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :fields="fields"
                :items="data"
              >
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(create)="{ item }">
                  <feather-icon
                    v-if="item.create == 1"
                    icon="CheckIcon"
                    class="text-success"
                    size="24"
                  />
                  <feather-icon
                    v-else
                    icon="XIcon"
                    class="text-danger"
                    size="24"
                  />
                </template>
                <template #cell(update)="{ item }">
                  <feather-icon
                    v-if="item.update == 1"
                    icon="CheckIcon"
                    class="text-success"
                    size="24"
                  />
                  <feather-icon
                    v-else
                    icon="XIcon"
                    class="text-danger"
                    size="24"
                  />
                </template>
                <template #cell(delete)="{ item }">
                  <feather-icon
                    v-if="item.delete == 1"
                    icon="CheckIcon"
                    class="text-success"
                    size="24"
                  />
                  <feather-icon
                    v-else
                    icon="XIcon"
                    class="text-danger"
                    size="24"
                  />
                </template>
                <!-- A custom formatted column -->
                <template #cell(action)="row">
                  <b-button
                    v-if="allowUpdate()"
                    variant="outline-info"
                    class="btn-icon"
                    @click="ModalUbah(row.item)"
                  >
                    <feather-icon icon="EditIcon"
                  /></b-button>
                  <b-button
                    v-if="allowDelete()"
                    variant="outline-danger"
                    class="btn-icon ml-1"
                    @click="ModalHapus(row.item)"
                  >
                    <feather-icon icon="TrashIcon"
                  /></b-button>
                </template>
                <!-- A custom formatted column -->
                <template #cell(Popularity)="data">
                  <b-progress
                    :value="data.value.value"
                    max="100"
                    :variant="data.value.variant"
                    striped
                  />
                </template>

                <template #cell(order_status)="data">
                  <b-badge pill :variant="data.value.variant">
                    {{ data.value.status }}
                  </b-badge>
                </template>

                <!-- A virtual composite column -->
                <template #cell(price)="data">
                  {{ "$" + data.value }}
                </template>

                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  {{ data.value }}
                </template>
              </b-table>
              <!-- <b-pagination
          v-if="record !== 0"
          :total="Math.ceil((total * 1) / itemsPerPage)"
          v-model="currentPage"
          @change="staf()"
        /> -->
              <!-- :prev-icon="isRTL ? 'arrow_forward' : 'arrow_back'"
          :next-icon="isRTL ? 'arrow_back' : 'arrow_forward'" -->

              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card-text>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ToastificationContent,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      filteredLevel: null,
      levelOptions: [],
      id: null,
      activeAction: null,
      label: "Simpan",
      //Data
      data: [],
      form: {
        menu_id: 0,
        level_id: 0,
        create: false,
        update: false,
        delete: false,
      },
      //Modal
      titleModal: null,
      Modal: false,
      ModalEdit: false,
      //Table
      fields: [
        { key: "index", label: "No" },
        { key: "level.nama_level", label: "Level", sortable: true },
        { key: "menu.name", label: "Menu", sortable: true },
        { key: "create", label: "Tambah" },
        { key: "update", label: "Ubah" },
        { key: "delete", label: "Hapus" },
        { key: "action", label: "#" },
      ],
      levels: [],
      menus: [],
      selected: [],
      total: [],
      record: [],
      optFilter: [
        { id: "nama_level", value: "Nama" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "Kategori",
      search: null,
      idd: null,
      loading: false,
    };
  },
  watch: {
    filteredLevel(levelId) {
      if (levelId || levelId == null) {
        this.getData();
      }
    },
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    resetForm() {
      this.form.menu_id = 0;
      this.form.level_id = 0;
      this.form.create = false;
      this.form.update = false;
      this.form.delete = false;
    },
    tutupModal() {
      this.Modal = false;
      this.resetForm();
      this.getData();
      this.getDatalevel();
      this.getDatamenu();
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    ModalShow() {
      this.id = null;
      this.resetForm();
      this.activeAction = "tambah";
      this.titleModal = "Tambah Role Management";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = item.id;
      this.form.menu_id = item.menu ? item.menu.id : null;
      this.form.level_id = item.level_id;
      this.form.create = item.create == 1 ? true : false;
      this.form.update = item.update == 1 ? true : false;
      this.form.delete = item.delete == 1 ? true : false;
      this.activeAction = "ubah";
      this.titleModal = "Ubah Role Management";
      this.Modal = true;
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 2; //soft delete
          this.$store
            .dispatch("role-management/createUpdateDelete", [data])
            .then(() => {
              this.getData();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    Tambah() {
      if (
        this.form.menu_id == null ||
        (this.form.menu_id == "" && this.form.level_id == null) ||
        this.form.level_id == ""
      ) {
        this.pesanGagal();
        return false;
      }
      let payload = {
        menu_id: this.form.menu_id,
        level_id: this.form.level_id,
        create: this.form.create,
        update: this.form.update,
        delete: this.form.delete,
      };

      if (this.id) {
        payload.id = this.id;
      }

      this.$store
        .dispatch("role-management/createUpdateDelete", [payload])
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.form.menu_id = 0;
          this.form.level_id = 0;
          this.form.create = false;
          this.form.update = false;
          this.form.delete = false;
          this.Modal = false;
          // this.ModalEdit = false;
          this.getData();
          this.displaySuccess({
            text: "Role Management berhasil di" + this.activeAction,
          });
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getData() {
      this.loading = true;
      const payload = {};

      if (this.filteredLevel) {
        payload.level_id = this.filteredLevel;
      }

      this.$store
        .dispatch("role-management/index", payload)
        .then((response) => {
          this.loading = false;
          let data = response.data.data;
          this.data = data;
          this.totalRows = this.data.length;
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        })
        .finally(() => {
          this.selected = [];
        });
    },
    getDatalevel() {
      this.$store.dispatch("level/index", {}).then((response) => {
        let level = response.data.data;
        level.map((item) => {
          item.value = item.id;
          item.text = item.nama_level;
        });
        this.levels = level;

        this.levelOptions.push({
          value: null,
          text: "-- Semua Level --",
        });

        level.forEach((lvl) => {
          this.levelOptions.push(lvl);
        });
      });
    },
    getDatamenu() {
      this.$store.dispatch("menu/index", {}).then((response) => {
        let menu = response.data.data;
        menu.map((item) => {
          item.value = item.id;
          item.text = item.name;
        });
        this.menus = menu;
      });
    },
  },
  created() {},
  async mounted() {
    this.getData();
    this.getDatalevel();
    this.getDatamenu();
  },
};
</script>

<style></style>
